import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import CTA from '../components/CTA';
import Layout from '../components/Layout';
import SimpleHeaderModule from '../components/SimpleHeaderModule';

export default () => (
  <Layout pageTitle="Cami" description="Cami is a ferocious beast.">
    <SimpleHeaderModule title="Cami will not be attending your meeting.">
      <span style={{marginRight: 12}}>But other people will, so</span>
      <CTA dest="WebApp" label="Try Miter Meow" />
      <StaticImage style={{marginTop: 32}} src="../../images/cami.jpg" alt="Cami is sleeping" />
    </SimpleHeaderModule>
  </Layout>
);
